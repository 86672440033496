<template>
    <div class="row">
        <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
         <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none d-md-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>Highlight Incorrect Words</h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
            <h4 class="text-center mb-4 fancy"><span>Highlight Incorrect Words</span></h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You will hear a recording. Some words in the transcription will differ from what the speakers says.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Listening and reading</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 2 - 3 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>The audio begins to plays automatically. You are only able to listen to the audio recording once.</li>
                <li>To select a word click on it using the left button on your mouse. If you change your mind, left-click on the word again to deselect it.</li>
                <li>Quickly read the transcription before you listen to focus on key words.</li>
                <li>You will be judged for listening and reading.</li>
            </ul>
            </template>
            <app-ListeningExtraInfo/>
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(questId != 0)}" v-if="splitPara">
            <h4>Highlight Incorrect Words</h4>
            <p class="questHeading">You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker said. Please click on the words that are different.</p>
            
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" ><p>{{title}} </p><app-Timer ref="timer"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.question_id" :idName="'question_id'" :tableName="'tblListeningHighlightInCorrectWords'"/>
            </div>
            </div>
            <div v-if="questionrecording" class="audio-container mb-5"><audio id="questionRec" :src="questionrecording" controls /></div>
            <div class="singleWords" v-for="(item,i) in splitPara" :key="i">
                <div class="singleWords" @click="selectWord(i)" :id="i" >{{item}}&nbsp;</div>
            </div>


            <div class="row mt-3">
                <div class="col-12 text-left mt-4" style="background: #001848;" v-if="answerSubmit">
                    <p style="padding-left: 0px !important; color: rgb(255, 255, 255);line-height:  unset !important;"><strong>Your Score:   <span class="pl-1">{{marksGot}}</span>/{{actualAnswer.length}}</strong></p>
                </div>
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5">
                    <b-button class="mr-2 pl-4 pr-4" variant="outline-info" @click="submitanswer()">Submit</b-button>
                    <b-button variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <toggle-button @change="checkAnswer()" :width="75"  :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Answer', unchecked: 'Answer'}"/>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-4" v-if="checkAnswerFlag">
                <h4 class="decorated" style="text-transform:capitalize;"><span>Answer</span></h4>
                    <div class="col-12 mb-2 mt-2 results text-left" >
                    <h5 style="text-transform:capitalize;">Transcript:- </h5>
                    <p style="display:block;padding-bottom: 0px !important;">
                        <ul >
                            <li v-for="item in actualAnswer" :key="item" class="rightAnswer"><strong> {{item}}</strong></li>
                        </ul>
                    </p>
                    </div>
                </div>
            </div>


            <!-- <div class="row">
                <div class="col-12 mb-4 mt-5 results" v-if="checkAnswerFlag == true">
                    <h6>Result:- </h6>
                    <p><strong>Your question result is {{this.marksGot}} out of {{this.totalMarks}}</strong></p>
                </div>
                 <div class="col-12 text-center mt-5" v-if="selectedquestion">
                    <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-5 prevQuestion">Prev Question</b-button>
                    <b-button variant="success" @click="submitanswer()">Find Answer & Marks</b-button>
                    <b-button @click="$refs.intervalList.changeQuestion('next')" class="ml-5 nextQuestion">Next Question</b-button>

                </div>
            </div>
            <div class="row">
                <div class="col-12" v-if="correctAnswer != null">
                    <ul >
                        <li v-for="item in correctAnswer" :key="item" class="rightAnswer"><strong> {{item}}</strong></li>
                    </ul>
                </div>
                
            </div> -->
        </div>
        <b-modal ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
             <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'question_id'"/></b-container>
        </b-modal>
        <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tbllisteninguserhighlightincorrectwordsanswer'"/>
        </div> 
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import $ from "jquery";
import Timer from '../reuseable/timer'
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import DiscussionTab from '../reuseable/discussionTab'
import ListeningExtraInfo from '../reuseable/listeningInformation.vue'
export default {
    components:{
        'app-Timer':Timer,
 'app-IntervalList': IntervalList,
 'app-QuestionStatus':QuestionStatus,
 'app-DiscussionTab':DiscussionTab,
 'app-ListeningExtraInfo':ListeningExtraInfo
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            selectedquestion: null,
            splitPara:null,
             answerLists:{
                type: Object,
                required: true
            },
            correctAnswer:[],
            questionrecording: null,
            title:'Prepare',
            userAnswerResponse:null,
            userAnswer:{
                url:'',
                user_id:0,
                question_id:0,
                answers:null,
                mock_test_id:0,
                user_score:''
            },
            options:[],
            totalMarks:0,
            marksGot:0,
            checkAnswerFlag: false,
            user_type:'Free',
            currentQuestion:0,
            actualAnswer:[],
            answerSubmit:false,
            searchKey:'',
            searchQuestionList:null,
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'listeningmodule/fetchQuestion',
            saveAnswer: 'listeningmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'listeningmodule/questions',
            answerResponse: 'listeningmodule/AnswerResponse',
            userDetail: 'auth/user'
        }),
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        resetAll(){
            $('.selected').each(function(){
                $(this).removeClass('selected')
            });
            $('.wrongWord').each(function(){
                $(this).removeClass('wrongWord')
            });
            this.totalMarks = 0
            this.marksGot = 0
            this.checkAnswerFlag = false
            this.correctAnswer=[]
            this.userAnswer={
                user_id:this.$ls.get('user').user_id,
                url:'',
                question_id:0,
                answers:null,
                mock_test_id:0,
                user_score:''
            }
            this.actualAnswer=[],
            this.answerSubmit=false
        },
        redoQuest(){
            this.getQuestionDetail(this.currentQuestion)
            $('.correctAnswer').remove()
        },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].question_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.question_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.currentQuestion=questionId
            this.resetAll()
           this.userAnswer.question_id = questionId;
           this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.question_id == questionId;
                })[0]
                if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
            ////console.log(this.selectedquestion.question_para)
            this.splitPara = this.selectedquestion.question_para.split(' ')
            ////console.log(this.splitPara)
            $('.singleWords').removeClass('selected')
            //this.questionservice('confighighlightincorrectwords/blobId='+questionId).then(()=>{
                var binary= this.selectedquestion.blb //this.getQuestionList().data.blb;
                //console.log(binary);
                this.questionrecording = binary;
                this.$nextTick(function(){
            this.$refs.discussionTab.updateQuestionId(questionId)

                this.$refs.timer.setTime(3)
                })
                var questionPara_Array=this.selectedquestion.question_para.split(' ');
                var correctPara_Array=this.selectedquestion.correct_para.split(' ');
                var vm= this
                $.each(questionPara_Array,function(index,item){
                if(item != correctPara_Array[index]){
                        vm.actualAnswer.push(correctPara_Array[index])
                        }
                    })
            //});  
        },
        async checkAnswer(){
            //console.log(this.checkAnswerFlag)
            $('.correctAnswer').remove()
            if(!this.checkAnswerFlag)
            {
                 $('.correctAnswer').remove()
                 $('.wrongWord').removeClass('wrongWord')
            }
            else
            {
            var questionPara_Array=this.selectedquestion.question_para.split(' ');
            var correctPara_Array=this.selectedquestion.correct_para.split(' ');
            var incorrectWords=[]
            var selectedWords=[]
            this.correctAnswer=[]
            this.totalMarks = 0
            this.marksGot = 0
            //this.checkAnswerFlag = true
            const vm = this
            $('.selected').each(function(index,item){
                //console.log(index)
                selectedWords.push(item.innerText.trim())
            })
            $.each(questionPara_Array,function(index,item){
                if(item != correctPara_Array[index]){
                    if(!$('#'+index).hasClass('selected'))
                    {
                        $('#'+index).addClass("wrongWord")
                        $('#'+index).after(function(){
                            return '<span class="singleWords correctAnswer"> '+correctPara_Array[index]+' </span>'
                        })
                    }
                    vm.correctAnswer.push(correctPara_Array[index])
                    incorrectWords.push(item)
                }
            })
            $.each(selectedWords,function(index,item){
                const matched=incorrectWords.filter(function(val){
                    return val==item
                })[0]
                vm.marksGot= (matched != null)? vm.marksGot+1:vm.marksGot-1;
            })
            this.totalMarks = this.correctAnswer.length;
            if(this.marksGot<0)this.marksGot=0
            this.userAnswer.user_score='Your Score: '+this.marksGot+'/'+ this.totalMarks 
            }
        },
        selectWord(divId){
            $('#'+divId).removeClass("wrongWord")
            $('#'+divId).toggleClass('selected')
        },
        removeClasses(){
            $('div.wrongSelection').removeClass('wrongSelection wrongAnswer')
            $('div.rightSection').removeClass('rightSection rightAnswer')
        },
         _arrayBufferToBase64( buffer ) {
            var binary = '';
            var bytes = new Uint8Array( buffer );
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] );
            }
            return window.btoa( binary );
        },
        timerEnd(){
               var aud = document.getElementById("questionRec");
               aud.play()
               this.$nextTick(function(){
                   this.title = 'Time'
                this.$refs.timer.setTime(0)
                })
        },
        async submitanswer() {
            var vm = this;
            this.checkAnswerFlag=true
            await this.checkAnswer()
            vm.options = [];
            $('.selected').each(function(index,item){
                const tempCt= {
                    answer_desc: item.innerHTML
                }
                vm.options.push(tempCt);
            });
            this.userAnswer.answers = this.options;
            var userdata = this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.userAnswer.mock_test_id=0
            this.userAnswer.url = 'confighighlightincorrectwordsanswer/createanswer';
            //console.log(this.options.length);
            if(this.options.length >0) {
                this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                    vm.userAnswerResponse = this.answerResponse().data;
                    //alert('Data has been saved successfully.');
                    //vm.resetAll();
                });
            }
            this.answerSubmit = true
                
        }
    },
    beforeMount(){
        this.questionLists=(this.$ls.get('tblListeningHighlightInCorrectWords')!=null)?JSON.parse(this.$ls.get('tblListeningHighlightInCorrectWords')):null;
            console.log(this.questionLists)
            if(this.questionLists!=null){
                this.questionLists = this.getQuestionList().data
            this.userAnswer.user_id = this.$ls.get('user').user_id
            this.user_type = this.$ls.get('user').user_type
            }
            else{
        this.questionservice('confighighlightincorrectwords/getallquestions').then(()=>{
            this.questionLists = this.getQuestionList().data
            this.userAnswer.user_id = this.$ls.get('user').user_id
            this.user_type = this.$ls.get('user').user_type
            //console.log(this.questionLists)
        });
    }
    }
}
</script>
<style scoped>
.singleWords{
    display: inline;
    cursor: pointer;
}
.selected{
    background:#12d3bf;
}
.wrongWord
{
background: #dda2a2;
padding: 0px 3px;
}
.text-center button{
    margin-right:30px
}

</style>
<style>
.correctAnswer
{
background: #17b856;
color: #fff;
font-weight: bold;
padding: 0px 3px;
}
</style>